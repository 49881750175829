<template>
  <div class="font">
    <section class="container mx-auto text-justify mt-4">
      <div class="flex justify-center mb-4">
        <span class="flex flex-1 items-center"><DoiLine class="line" /></span>
        <DoiHeader
          class="flex text-center title"
          :level="1"
          :headerColor="1"
          :fontFamily="0"
          >CONDITIONS GÉNÉRALES D’UTILISATION ET DE SERVICE</DoiHeader
        >
        <span class="flex flex-1"></span>
      </div>
      <DoiHeader
        :level="2"
        :headerColor="2"
        :fontFamily="0"
        class="text-4xl text-center mb-10 2k:text-4xl 4k:text-6xl"
        >Fit Arena</DoiHeader
      >
      <div class="rgpd 2k:text-xl 4k:text-4xl">
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          Les présentes conditions générales d’utilisation et de vente (ci-après
          désignées « <b>Conditions Générales</b> ») régissent sans restriction
          ni réserve les relations entre tout utilisateur du site internet, de
          l’application et des installations physiques Fit Arena (ci-après
          dénommés les « <b>Utilisateur(s)</b> ») et la société FIT ARENA,
          société par actions simplifiée au capital de 25.000,00 euros ayant son
          siège social sis 250 rue du petit bois, Parc d’activités des
          Platières, 69440 Mornant, France (ci-après dénommé « FIT ARENA »).
          <br />
          <br />
          Ces Conditions Générales ont pour objet de régir les conditions
          d’utilisation du site internet (ci-après désigné le «
          <b>Site Internet</b> »), de l’application « Fit Arena » (ci-après
          désignée l’ « <b>Application</b> ») (ci-après désignés ensemble la «
          <b>Plateforme</b> »), et des installations Fit Arena ainsi que les
          modalités de services proposés par FIT ARENA (tels que définis à
          l’article 2). <br /><br />
          <b
            >Ces Conditions Générales sont accessibles à tout moment sur le Site
            Internet et l’Application Fit Arena et prévaudront, le cas échéant,
            sur toute autre version ou tout autre document contradictoire.</b
          >
          <br /><br />
          Ces Conditions Générales pouvant faire l'objet de modifications
          ultérieures, la version applicable à la réservation ou à l'achat de
          l’Utilisateur est celle en vigueur sur le Site Internet et sur
          l’Application à la date de la réservation ou de la commande.
          <br /><br />
          <b
            >Ces Conditions Générales pourront être complétées par des
            conditions particulières spécifiquement applicables à chaque Fit
            Arena, gérée sous la responsabilité du Gestionnaire de Site, tel que
            défini ci-après, qui précisera certaines modalités d’utilisation
            (ci-après désignées les « Conditions Particulières »).</b
          >
          <br /><br />
          <b>Il est possible de contacter FIT ARENA de la manière suivante :</b>
          <br />
        </DoiText>
        <BulletList class="ml-10">
          <li>par téléphone : <i>04 78 67 60 56,</i></li>
          <li>par fax : <i>04 78 67 99 72,</i></li>
          <li>par mail : <i>contact@fit-arena.com,</i></li>
          <li>
            par voie postale :
            <i
              >FIT ARENA, 250 rue du petit bois, Parc d’activités des Platières,
              69440 Mornant, France.</i
            >
          </li> </BulletList
        ><br />
        <DoiText :textColor="1" :fontSize="3"
          >Les infrastructures Fit Arena (salles de sports) sont localisées sur
          la Plateforme.</DoiText
        >
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >1. Acceptation des Conditions Générales</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          L’Utilisateur déclare avoir pris connaissance des présentes Conditions
          Générales et les avoir acceptées en cochant la case prévue à cet effet
          avant la mise en œuvre de la procédure de réservation en ligne.
          <br /><br />
          Sauf preuve contraire, les données enregistrées par FIT ARENA
          constituent la preuve de l'ensemble des transactions passées par FIT
          ARENA et l’Utilisateur via le Site Internet et l’Application Fit
          Arena.
        </DoiText>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >2. Définitions</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          Les termes suivants auront, pour l’exécution et l’interprétation des
          présentes Conditions Générales, le sens défini ci-après qu’ils soient
          utilisés au singulier ou au pluriel :
          <br /><br />
          « Adhérent » désigne tout Utilisateur de la Plateforme inscrit auprès
          d’un Organisme Sportif, autorisé à s’inscrire dans la Fit Arena où
          l’Organisme Sportif dispose de plages horaires dédiées à la pratique
          sportive de ses Adhérents.
          <br /><br />
          « Donnée » désigne l’ensemble des informations qui transitent dans la
          Plateforme, en ce compris les Données Personnelles.
          <br /><br />
          « Données Personnelles » désignent les données qui, au sens de la
          règlementation applicable, particulièrement la loi Informatique et
          Liberté du 6 janvier 1978 modifiée et le Règlement général sur la
          protection des données du 27 avril 2016 (ci-après « RGPD »),
          permettent de désigner ou d'identifier, directement ou indirectement,
          une personne physique.
          <br /><br />
          « Utilisateur » désigne tout internaute ou toute personne qui accède
          et utilise les services proposés par la Plateforme.
          <br /><br />
          « Gestionnaire de Site » désigne toute entité juridique propriétaire
          et/ou exploitante d’une Fit Arena.
          <br /><br />
          « Organisme Sportif » désigne toute entité juridique, particulièrement
          les associations sportives ou tout organisme, auquel le Gestionnaire
          de Site affecte des plages horaires dédiés pour la pratique de(des)
          l’activité(s) sportive(s) que l’Organisme Sportif propose à ses
          Adhérents.
          <br /><br />
          « Animateur » désigne toute personne physique, préposé de l’Organisme
          Sportif, qui encadre et est responsable d’une session sportive
          organisée au sein d’une Fit Arena au bénéfice des Adhérents, selon les
          modalités prévues par le Gestionnaire de Site et l’Organisme Sportif.
          <br /><br />
        </DoiText>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >3. Caractéristiques principales des Services</DoiHeader
        >
        <NumericList class="sm:ml-10 ml-5">
          <li>
            FIT ARENA permet aux Utilisateurs de la Plateforme de réserver une
            salle de sport équipée (Fit Arena) dédiée à la pratique d’activités
            sportives proposées selon la Fit Arena sélectionnée, et qui comprend
            notamment des terrains de badminton, des terrains de foot à cinq,
            des terrains de basket et des terrains de padel (ci-après les «
            Services »).
          </li>
          <br />
          <li>
            Chaque Fit Arena est gérée sous la responsabilité du Gestionnaire de
            Site qui organise les plages horaires de disponibilité selon ses
            propres contraintes.
          </li>
          A cet égard, certaines plages horaires pourront être attribuées
          spécifiquement à des Organismes Sportifs et des Animateurs, d’autres
          pourront être réservables par tout Utilisateur de la Plateforme.<br /><br />
          <li>
            Le Gestionnaire de Site est seul responsable de la répartition des
            plages horaires dédiées.
          </li>
          <br />
          <li>
            FIT ARENA assure l’administration technique et fonctionnelle de la
            Plateforme.
          </li>
          Chaque Fit Arena et page dédiée à ladite Fit Arena sur la Plateforme
          sont administrées sous la responsabilité exclusive du Gestionnaire de
          Site.
        </NumericList>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >4. Création – Gestion – Clôture de compte</DoiHeader
        >
        <NumericList class="sm:ml-10 ml-5">
          <DoiHeader class="underline mb-2 text-xl" :level="3"
            >Ouverture de Compte Utilisateur</DoiHeader
          >
          <li>
            Pour bénéficier de l’ensemble des Services proposées par la
            Plateforme, chaque Utilisateur doit créer un compte utilisateur
            (ci-après désigné le « Compte »).
          </li>
          <br />
          <li>
            La création d’un Compte est réservée aux Utilisateurs de plus de 16
            ans révolus. Chaque Compte est personnel.
          </li>
          <br />
          <li>
            Pour l’ouverture de son Compte, l’Utilisateur devra fournir des
            Données Personnelles, obligatoires ou facultatives, dans les
            conditions définies à la Politique de Confidentialité accessible
            <router-link to="/politique-de-confidentialite" class="red"
              >ici.</router-link
            >
          </li>
          <br />
          <li>
            L’Utilisateur reconnaît avoir la capacité requise pour contracter et
            bénéficier des Services.
          </li>
          <br />
          <li>
            La création d’un Compte peut être réalisée sur le Site Internet ou
            sur l’Application Fit Arena.
          </li>
          <br />
          <li>
            L’Utilisateur est tenu de fournir des informations exactes et
            s’engage à les mettre à jour en cas de modifications.
          </li>
          <br />
          <DoiHeader class="underline mb-2 text-xl" :level="3"
            >Gestion de Compte Utilisateur</DoiHeader
          >
          <li>
            L’accès au Compte créé est ensuite protégé par un e-mail et un mot
            de passe confidentiel dont seul l’Utilisateur a connaissance.
          </li>
          <br />
          <li>
            L’Utilisateur reconnait et accepte être tenu de conserver
            confidentiels ses paramètres de connexion (e-mail et mot de passe).
          </li>
          <br />
          <li>
            L’Utilisateur est responsable de toutes les utilisations de son
            compte, qu’il les ait ou non expressément autorisées.
          </li>
          <br />
          <li>
            Il est formellement interdit à l’Utilisateur de céder, prêter, ou
            transférer ses paramètres de connexion à tout tiers ou de permettre
            à un tiers par quelque moyen que ce soit, de se connecter à son
            Compte.
          </li>
          <br />
          <li>
            L’Utilisateur peut créer au sein de son Compte plusieurs profils de
            joueurs dont il assume l’entière responsabilité. A cet égard,
            l’Utilisateur sera exclusivement responsable des informations qu’il
            fournit à la Plateforme pour la création de son Compte et pour la
            création des profils de joueurs rattachés à son Compte.
          </li>
          <br />
          <li>
            Dans le cas de profils joueurs créés par l’Utilisateur, ce dernier
            assume l’entière responsabilité de l’utilisation des Services et de
            l’accès aux Fit Arena par les personnes physiques correspondant aux
            profils joueurs de son Compte.
          </li>
          <br />
          <DoiHeader class="underline mb-2 text-xl" :level="3"
            >Clôture de Compte Utilisateur</DoiHeader
          >
          <li>
            Chaque Utilisateur a la possibilité de clôturer son Compte
            Utilisateur à tout moment en en faisant la demande à l’adresse
            suivante : contact@fit-arena.com.
          </li>
          <br />
          <li>
            FIT ARENA se réserve le droit de clôturer le Compte d’un Utilisateur
            et de lui interdire l’accès aux Services en cas de défaut de
            paiement de la réservation, de dommages aux infrastructures et
            équipements mis à disposition, de violation des présentes Conditions
            Générales ou des Conditions Particulières ou de tout comportement
            incompatible avec l’utilisation normale des équipements.
          </li>
        </NumericList>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >5. Réservations</DoiHeader
        >
        <NumericList class="sm:ml-10 ml-5">
          <li>
            La Plateforme permet la réservation de temps de jeu dans une Fit
            Arena sélectionnées par l’Utilisateur pour un nombre de participants
            défini par l’Utilisateur Organisateur (ci-après désignés ensemble la
            « Session »).
          </li>
          <br />
          <li>
            La réservation d’une Session est réalisée en ligne par un seul
            Utilisateur (ci-après désigné l’« Utilisateur Organisateur »).
          </li>
          <br />
          <li>
            Les caractéristiques principales des Services, sont présentées sur
            le Site Internet et sur l’Application.
          </li>
          <br />
          <li>
            L’Utilisateur est tenu d'en prendre connaissance avant toute
            réservation. Le choix et l'achat d'un Service sont réalisés sous la
            responsabilité exclusive de l’Utilisateur et des personnes qu’il
            représente.
          </li>
          <br />
          <li>
            Les informations contractuelles sont présentées en langue française
            et font l'objet d'une confirmation au plus tard au moment de la
            validation de la réservation par l’Utilisateur Organisateur.
          </li>
          <br />
          <li>
            L’Utilisateur Organisateur sélectionne sur la Plateforme les
            Services qu'il désire réserver, selon les modalités suivantes :
          </li>
          <List class="sm:ml-10 ml-3">
            <HyphenListItem
              >Choix de la Fit Arena localisée géographiquement et acceptation
              des Conditions Particulières applicables,</HyphenListItem
            ><br />
            <HyphenListItem
              >Visionnage de la visite virtuelle de la Fit Arena
              sélectionnée,</HyphenListItem
            ><br />
            <HyphenListItem
              >Choix de la configuration du terrain de sport (notamment
              badminton, basket, foot en salle, padel),</HyphenListItem
            ><br />
            <HyphenListItem
              >Choix de la date et du créneau horaire selon les propositions
              disponibles,</HyphenListItem
            ><br />
            <HyphenListItem>Récapitulatif de la réservation,</HyphenListItem
            ><br />
            <HyphenListItem
              >Acceptation des Conditions Générales et Particulières applicables
              et validation des critères de la réservation,</HyphenListItem
            ><br />
            <HyphenListItem
              >Paiement par carte bancaire de la réservation via le module de
              paiement sécurisé dédié, dans les conditions définies ici par
              Payzen,</HyphenListItem
            ><br />
            <HyphenListItem>Confirmation de la réservation,</HyphenListItem
            ><br />
            <HyphenListItem
              >Envoi d’une confirmation de réservation du terrain avec le détail
              de la réservation à l’adresse email rattachée au Compte de
              l’Utilisateur Organisateur. Ces informations seront également
              disponibles dans les détails du Compte de l’Utilisateur pendant la
              durée de conservation des Données, dans les conditions définies
              dans la Politique de Confidentialité accessible
              <router-link
                to="/politique-de-confidentialite"
                class="text-fa-red"
                >ici</router-link
              >,</HyphenListItem
            ><br />
            <HyphenListItem
              >Envoi d’un lien par message SMS au numéro de téléphone renseigné
              sur le compte de l’Utilisateur Organisateur à transmettre aux
              autres Utilisateurs de la session réservée.</HyphenListItem
            >
          </List>
          <br />
          <li>
            La réservation de Services ne sera considérée comme définitive
            qu'après l'envoi à l’Utilisateur Organisateur de la confirmation de
            l'acceptation de la réservation par FIT ARENA, par courrier
            électronique et après encaissement par celui-ci de l'intégralité du
            prix.
          </li>
          <br />
          <li>
            Lors de la réservation d’une Session sur la Plateforme,
            l’Utilisateur Organisateur devra effectuer la visite virtuelle de la
            Fit Arena. L’Utilisateur Organisateur est garant du respect des
            consignes de sécurité applicables à la Fit Arena concernée. La
            visite virtuelle et les consignes de sécurité sont également mises à
            disposition des Utilisateurs sur chaque site.
          </li>
          <br />
          <li>
            Une fois la Session réservée par l’Utilisateur Organisateur,
            celui-ci recevra un lien à diffuser à tous les autres Utilisateurs
            participant à la Session, lesquels devront alors s’inscrire ou
            s’identifier sur la Plateforme pour recevoir le flash-code leur
            permettant d’accéder à la Fit Arena.
          </li>
          <br />
          <li>
            Chaque Utilisateur dispose de son propre flash-code d’accès qui lui
            est personnel et qui lui permet d’accéder à titre individuel à la
            Fit Arena pour le déroulement de la Session à laquelle il est
            rattaché.
          </li>
          <br />
          <li>
            L’Utilisateur assume seul la responsabilité de l’usage de ce
            flash-code unique. A ce titre, il s’engage à ne pas transmettre son
            flash-code pour toute utilisation qui ne serait pas conforme aux
            Conditions Générales de la Plateforme et aux Conditions
            Particulières applicables à la Fit Arena.
          </li>
          <br />
          <li>
            Par exception à ce qui précède, lorsque l’Utilisateur Organisateur
            est Animateur, et seulement dans ce cas précis, il dispose d’un
            flash-code spécifique qui lui permet de gérer l’entrée de ses
            Adhérents dans les conditions applicables aux Organismes Sportifs.
          </li>
          <br />
          <li>
            Les Utilisateurs comprennent et reconnaissent que seule la création
            d’un Compte Fit Arena via la Plateforme leur permettra d’obtenir
            leur flash-code et d’accéder à la salle de sport.
          </li>
          <br />
          <li>
            La réservation de la configuration souhaitée de la Fit Arena
            concernée ne pourra pas être modifiée après le début de la Session.
          </li>
          <br />
          <li>
            Les réservations étant exclusivement réalisées sur Internet,
            l'enregistrement d'une réservation sur la Plateforme est subordonné
            à l’acceptation préalable des présentes Conditions Générales et des
            Conditions Particulières éventuellement applicables en cochant les
            cases prévues à cet effet.
          </li>
          <br />
          <li>
            L’Utilisateur Organisateur a la possibilité de vérifier le détail de
            sa réservation, son prix total et de corriger d'éventuelles erreurs
            avant de confirmer son acceptation (article 1127-2 du Code civil).
          </li>
          <br />
          <li>
            Cette validation implique l'acceptation de l'intégralité des
            présentes Conditions Générales et constitue une preuve du contrat de
            vente. Il appartient donc à l’Utilisateur Organisateur de vérifier
            l'exactitude de la réservation et de signaler immédiatement toute
            erreur.
          </li>
          <br />
          <li>
            Toute réservation effectuée sur la Plateforme constitue la formation
            d'un contrat conclu à distance entre l’Utilisateur Organisateur et
            le Gestionnaire du Site. FIT ARENA intervient pour mettre à
            disposition des Utilisateurs et des Gestionnaires de Sites l’outil
            technique et fonctionnel qui permet la conclusion de ce contrat : la
            Plateforme.
          </li>
          <br />
          <li>
            Le Gestionnaire de Site et FIT ARENA se réservent le droit d'annuler
            ou de refuser toute réservation d'un Utilisateur avec lequel il
            existerait un différend ou présentant un risque particulier.
          </li>
          <br />
          <li>
            Le Gestionnaire de Site et FIT ARENA se réservent le droit d’annuler
            une réservation pour permettre l’entretien des terrains et des
            infrastructures, pour réparer ou résoudre tout problème technique,
            ou pour permettre un événement particulier sur le site.
          </li>
        </NumericList>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >6. Tarifs</DoiHeader
        >
        <NumericList class="sm:ml-10 ml-5">
          <li>
            Les Services proposés par FIT ARENA sont fournis aux tarifs en
            vigueur sur la Plateforme au moment de la réservation et selon les
            indications établies lors de l'enregistrement de la réservation par
            l’Utilisateur.
          </li>
          <br />
          <li>Les prix sont exprimés en Euros, Toutes Taxes Comprises.</li>
          <br />
          <li>
            Le Gestionnaire de Site et FIT ARENA se réservent le droit de
            modifier les prix à tout moment. Les nouveaux prix entreront en
            vigueur dès leur affichage sur la Plateforme pour les réservations
            validées postérieurement à cet affichage.
          </li>
          <br />
          <li>
            Le paiement demandé à l’Utilisateur correspond au montant total de
            l'achat.
          </li>
          <br />
          <li>
            L’utilisateur Organisateur aura la possibilité de solliciter les
            Utilisateurs participant à la Session pour le règlement de la
            quote-part leur revenant. Chaque utilisateur participant sera
            débité, après la partie, d'une somme équivalente au montant total de
            sa session divisé par le nombre de participants.
          </li>
          <br />
          <li>
            Une facture est établie par la Plateforme et remise à l’Utilisateur
            lors de la confirmation de la réservation par email.
          </li>
        </NumericList>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >7. Conditions de paiement</DoiHeader
        >
        <NumericList class="sm:ml-10 ml-5">
          <li>
            Le prix est payable comptant, en totalité au jour de la validation
            de la réservation par l’Utilisateur Organisateur, selon les
            modalités précisées à l'article « Réservations » ci-dessus, via un
            module de paiement sécurisé payzen :
          </li>
          <List class="sm:ml-10 ml-3">
            <HyphenListItem
              >par cartes bancaires : Visa, MasterCard, American Express, autres
              cartes bleues (Ecard bleue).</HyphenListItem
            > </List
          ><br />
          <li>
            FIT ARENA et le Gestionnaire de Site ne seront pas tenus de fournir
            les Services commandés par l’Utilisateur si le prix ne leur a pas
            été préalablement réglé en totalité dans les conditions prévues aux
            Conditions Générales et Particulières.
          </li>
          <br />
          <li>
            Les paiements effectués par l’Utilisateur Organisateur ne seront
            considérés comme définitifs qu'après encaissement effectif des
            sommes dues par la Plateforme.
          </li>
          <br />
          <li>
            FIT ARENA décline toute responsabilité quant à la répercussion du
            coût de la réservation que pourrait faire l’Utilisateur Organisateur
            sur les autres Utilisateurs. L’Utilisateur Organisateur reconnait et
            accepte que FIT ARENA ne sera pas responsable envers lui en cas
            d’absence de remboursement de la part d’un Utilisateur.
          </li>
        </NumericList>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >8. Fourniture des Services – déroulement d’une Session</DoiHeader
        >
        <NumericList class="sm:ml-10 ml-5">
          <li>
            Les Services réservés ou commandés par l’Utilisateur, qui
            comprennent les prestations d’accès à la salle de sport et de
            location de terrains de sport seront fournis à une date et pendant
            une durée déterminées lors de sa réservation par l’Utilisateur, dans
            les conditions prévues aux présentes Conditions Générales et
            Particulières applicables.
          </li>
          <br />
          <li>
            FIT ARENA s'engage à faire ses meilleurs efforts pour fournir les
            Services commandés par l’Utilisateur, dans le cadre d'une obligation
            de moyen pour la date et la durée précisées.
          </li>
          <br />
          <li>
            Les Utilisateurs participant à une Session pourront entrer dans la
            Fit Arena quinze (15) minutes avant l’heure prévue de début de la
            Session.
          </li>
          <br />
          <li>
            Pour accéder à la Fit Arena, objet de la réservation, les
            Utilisateurs devront se munir des flash-codes qui leur auront été
            attribués dans les conditions définies aux présentes Conditions
            Générales.
          </li>
          <br />
          <li>
            Chaque Utilisateur devra scanner son flash-code personnel sur le
            panneau prévu à cet effet à l’entrée de la Fit Arena.
          </li>
          <br />
          <li>
            Les Utilisateurs en situation de handicap dispose d’un portique
            spécifique qui leur permet de scanner leur flash-code et d’accéder à
            la Fit Arena.
          </li>
          <br />
          <li>
            L’utilisateur Organisateur assume la responsabilité de l’accès au
            site et le respect par l’ensemble des Utilisateurs présents des
            consignes de sécurité applicables.
          </li>
          <br />
          <li>
            En cas de retard des Utilisateurs sur le créneau horaire réservé,
            l’heure de réservation ne pourra être décalée en raison de créneaux
            fixes et des réservations suivantes.
          </li>
          <br />
          <li>
            Les Utilisateurs disposent d’un espace dédié aux vestiaires et à la
            préparation de la Session. Les Utilisateurs pourront notamment
            renseigner les équipes, les temps de jeu et toutes autres
            informations utiles au déroulement de la Session sur les écrans
            prévus à cet effet dans l’espace dédié, avant l’accès au terrain.
          </li>
          <br />
          <li>
            Les Utilisateurs pourront accéder au terrain, configuré selon les
            termes de la réservation effectuée par l’Utilisateur Organisateur, à
            l’heure prévue de début de la Session.
          </li>
          <br />
          <li>
            Les terrains des Fit Arena sont équipés d’écrans tactiles permettant
            notamment d’enregistrer les matchs, de visionner les
            enregistrements, d’identifier les moments forts et de grands écrans
            permettant d’afficher le score et la feuille de match en temps réel.
          </li>
          <br />
          <li>
            Des caméras sont présentes dans la Fit Arena et sont utilisées dans
            le but d’enregistrer les Sessions et à des fins de sécurité. Les
            modalités de traitement des Données sont définies dans la Politique
            de Confidentialité accessible
            <router-link to="/politique-de-confidentialite" class="text-fa-red"
              >ici.</router-link
            >
          </li>
          <br />
          <li>
            La feuille de match ainsi que la vidéo de la Session sont
            accessibles dans l’historique personnel des Comptes Utilisateurs de
            chaque Utilisateur de la session concernée, sur le Site Internet et
            sur l’Application.
          </li>
          <br />
          <li>
            Les vidéos sont conservées pour une durée définie dans la Politique
            de Confidentialité. Elles peuvent également être téléchargées
            localement et être partagées sur les réseaux sociaux par les
            Utilisateurs sous les réserves et limitations de responsabilité.
          </li>
          <br />
          <li>
            Chaque Fit Arena est équipée d’un distributeur de chasubles et de
            matériels qui permet l’achat ou la location de matériel pour la
            Session.
          </li>
          <br />
          <li>[caution à prévoir en cas de dégradation ?]</li>
        </NumericList>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >9. Absence de fourniture des Services</DoiHeader
        >
        <NumericList class="sm:ml-10 ml-5">
          <li>
            Si les Services commandés n'ont pas été fournis à la date précisée
            dans la réservation, pour toute autre cause que la force majeure ou
            le fait de l’Utilisateur, l’Utilisateur Organisateur aura droit,
            selon son choix i) à la réservation d’une autre Session en
            remplacement des Services qui n’auront pas pu être fournis, soit ii)
            au remboursement des sommes versées pour la réservation en question.
          </li>
          <br />
          <li>
            Les sommes versées par l’Utilisateur Organisateur lui seront alors
            restituées au plus tard dans les quatorze jours qui suivent la date
            de résolution du contrat, à l'exclusion de toute indemnisation ou
            retenue.
          </li>
          <br />
          <li>
            Des majorations sont appliquées en cas de retard de remboursement :
            10 % si le remboursement intervient dans les trente jours au-delà de
            ce terme, 20 % jusqu'à soixante jours et 50 % au-delà.
          </li>
          <br />
        </NumericList>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >10. Défaut de conformité des Services</DoiHeader
        >
        <NumericList class="sm:ml-10 ml-5">
          <li>
            La salle de sport fonctionnant sans personnel sur place, les
            Utilisateurs pourront notifier à FIT ARENA toute réclamation et tout
            défaut de conformité des Services sur la Plateforme ou par email à
            l’adresse électronique suivante : contact@fit-arena.com.
          </li>
          <br />
          <li>
            L’Utilisateur reconnait et accepte qu’un défaut de conformité ne
            peut résulter que des défauts suivants :
          </li>
          <List class="sm:ml-10 ml-3">
            <HyphenListItem
              >impossibilité d’accéder à la salle (causée par exemple par un
              défaut du tourniquet ou un bug de l’Application) en dehors des cas
              dont la responsabilité incombe à l’Utilisateur (par exemple oublie
              de son flash-code, absence à l’heure de la
              réservation),</HyphenListItem
            ><br />
            <HyphenListItem>terrains impraticables,</HyphenListItem><br />
            <HyphenListItem
              >matériel détérioré rendant impossible la pratique du sport
              sélectionné.</HyphenListItem
            >
          </List>
          <br />
          <li>
            Les Utilisateurs disposeront d'un délai de 7 jours à compter du jour
            prévue du déroulement de la Session qui fait l’objet d’un défaut,
            pour notifier, sur la Plateforme ou par email à l’adresse
            électronique précisée ci-dessus, le défaut de conformité à FIT ARENA
            avec tous les justificatifs y afférents.
          </li>
          <br />
          <li>
            À défaut de notification des défauts de conformité expressément
            émise par l’Utilisateur dans ce délai et sous cette forme, les
            Services seront réputés conformes à la réservation, en quantité et
            qualité.
          </li>
          <br />
          <li>
            Lorsque le défaut de conformité aura été dument prouvé par
            l’Utilisateur, FIT ARENA remboursera totalement celui-ci, pour la
            Session concernée, dans les plus brefs délais.
          </li>
        </NumericList>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >11. Droit de rétractation - Annulation
        </DoiHeader>
        <NumericList class="sm:ml-10 ml-5">
          <li>
            Compte tenu du fait que les Services de location de terrain sont
            fournis dans un délai très court et le plus souvent inférieur à
            quatorze jours entre la réservation sur la Plateforme et l’exécution
            des Services, les réservations passées par l’Utilisateur moins de
            quatorze jours avant la date du Service ne bénéficient pas du droit
            de rétractation conformément à l’article L.121-21-8 1° du Code de la
            consommation reproduit ci-dessous.<br /><br />
            <u>Article L.121-21-8 1° du Code de la consommation :</u><br />
            <i
              >« Le droit de rétractation ne peut être exercé pour les contrats
              :<br /><br />
              1° De fourniture de services pleinement exécutés avant la fin du
              délai de rétractation et dont l'exécution a commencé après accord
              préalable exprès du consommateur et renoncement exprès à son droit
              de rétractation. »</i
            >
          </li>
          <br />
          <li>
            En revanche, l’Utilisateur Organisateur dispose d’un
            <b>droit d’annulation</b> de sa réservation. Cette annulation de la
            réservation peut être effectuée sur le Site Internet ou sur
            l’Application Fit Arena dans la rubrique « réservation » en cliquant
            sur l’icône « annuler ma réservation ».
          </li>
          <br />
          <li>
            Si l’annulation intervient plus de 48 heures avant la Session,
            l’Utilisateur Organisateur sera remboursé sans frais de la totalité
            des montants versés pour la réservation.
          </li>
          <br />
          <li>
            Si l’annulation intervient moins de 48 heures avant la Session,
            l’Utilisateur Organisateur ne sera pas remboursé et l’intégralité
            des sommes seront acquises à FIT ARENA et au Gestionnaire de Site.
          </li>
        </NumericList>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >12. Responsabilité de FIT ARENA</DoiHeader
        >
        <NumericList class="sm:ml-10 ml-5">
          <li>
            FIT ARENA ne saurait être tenue que d’une obligation de moyen au
            titre de la mise à disposition des Services.
          </li>
          <br />
          <li>
            La responsabilité de FIT ARENA ne peut être engagée en cas
            d’inexécution ou de mauvaise exécution de ses obligations qui serait
            due, soit au fait de l’Utilisateur (et notamment d’une utilisation
            anormale de la salle), soit au fait insurmontable et imprévisible
            d’un tiers au contrat, soit à un cas de force majeure.
          </li>
          <br />
          <li>
            En tout état de cause, la responsabilité de FIT ARENA ne saurait
            être directement ou indirectement retenue, à quelque titre et pour
            quelque cause que ce soit, en cas de :
          </li>
          <List class="sm:ml-10 ml-3">
            <HyphenListItem
              >tout accident lié à la pratique du sport en question qui ne
              serait pas lié à un mauvais entretien du site, ou un défaut
              quelconque des terrains de sport mis à
              disposition,</HyphenListItem
            ><br />
            <HyphenListItem
              >tout préjudice causé par une mauvaise utilisation de son Compte
              Utilisateur Fit Arena, que cette mauvaise utilisation résulte du
              fait de l’Utilisateur ou de celui d’un tiers,</HyphenListItem
            ><br />
            <HyphenListItem
              >tout préjudice que pourrait causer toute mauvaise utilisation de
              la vidéo et de la feuille de match des Sessions sportives par un
              Utilisateur ou un tiers,</HyphenListItem
            ><br />
            <HyphenListItem
              >toute difficulté de remboursement de l’Utilisateur Organisateur
              par les autres Utilisateurs de la Session,</HyphenListItem
            ><br />
            <HyphenListItem
              >toute perturbation ou indisponibilité du réseau internet lors de
              l’utilisation du Site Internet ou de l’Application Fit
              Arena,</HyphenListItem
            ><br />
            <HyphenListItem
              >toute contamination des matériels informatiques de l’Utilisateur
              résultant de la propagation d'un virus ou autres infections
              informatiques (étant précisé que l'Utilisateur est seul
              responsable de la sécurité de son matériel informatique et doit
              prendre toutes les mesures appropriées de façon à protéger ses
              Données et/ou logiciels d’une contamination par des éventuels
              virus circulant sur les réseaux),</HyphenListItem
            ><br />
            <HyphenListItem
              >toute indisponibilité temporaire du site notamment pour des
              raisons de maintenance,</HyphenListItem
            ><br />
            <HyphenListItem
              >toute interruption de service imputable aux comportements d’un ou
              plusieurs Utilisateurs.</HyphenListItem
            >
          </List>
        </NumericList>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >13. Responsabilité de l’Utilisateur</DoiHeader
        >
        <NumericList class="sm:ml-10 ml-5">
          <li>
            Chaque Utilisateur est conscient des risques inhérents à la pratique
            des sports proposés dans les Fit Arena et reconnait et accepte être
            responsable de ses faits et gestes. Il est notamment responsable de
            tout dommage corporel causé aux autres Utilisateurs.<br />
            L’Utilisateur déclare que son état de santé est compatible avec la
            pratique du sport sélectionné pour le déroulement de la Session et
            assume l’entière responsabilité de cette pratique.
          </li>
          <br />
          <li>
            L’Utilisateur déclare avoir souscrit une assurance couvrant les
            dommages causés aux autres Utilisateurs.
          </li>
          <br />
          <li>
            L’Utilisateur reconnait et accepte qu’il est responsable de
            l’ensemble des coûts de réparation des dégradations du site, du
            matériel mis à disposition et des terrains de sport qu’il
            occasionnerait.
          </li>
          <br />
          <li>
            L’Utilisateur reconnaît et accepte que la preuve pourra être
            rapportée par tout moyen et notamment par les vidéos de
            surveillance.
          </li>
          <br />
          <li>
            FIT ARENA se réserve le droit d’engager des poursuites à l’encontre
            du ou des Utilisateur(s) qui aurai(en)t causé de telles
            dégradations.
          </li>
          <br />
          <li>
            Dans le cas où l’Utilisateur ayant causé les dégradations n’est pas
            individualisable, l’Utilisateur Organisateur ayant réservé la
            Session au cours de laquelle se sont produits les dégradations
            reconnait et accepte qu’il sera considéré comme responsable
            <b>de l’ensemble des coûts de réparation</b> de ces dégradations.
          </li>
          <br />
          <li>
            Seuls les Utilisateurs munis d’un flash-code sont autorisés à
            rentrer dans la Fit Arena. L’Utilisateur qui ferait volontairement
            entrer un tiers ne possédant pas de flash-code sur le site est
            responsable de tout dommage que pourrait causer celui-ci aux
            infrastructures Fit Arena et aux Utilisateurs, ainsi que des coûts
            afférents à l’inscription à laquelle aurait dû procéder le tiers
            concerné.
          </li>
          <br />
          <li>
            L’Utilisateur Organisateur reconnait et accepte que les sommes
            nécessaires à la réparation puissent être prélevées sur son Compte
            en cas de dégradation des équipements, des infrastructures ou du
            matériel mis à la disposition des Utilisateurs de sa Session.
          </li>
          <br />
          <li>
            Dans le cas spécifique selon lequel l’Utilisateur Organisateur est
            un Organisme Sportif ou un Animateur, l’Utilisateur Organisateur est
            responsable de l’ensemble des agissements des participants à la
            Session. Il est donc responsable de leur accès au site, de leur
            utilisation des équipements et des coûts de réparation des
            dégradations du site, du matériel mis à disposition et des terrains
            de sport que ceux-ci occasionneraient.
          </li>
        </NumericList>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >14. Données personnelles – Cookies</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3"
          >Les conditions dans lesquelles FIT ARENA, les Gestionnaires de Sites,
          les Organismes Sportifs et les Animateurs et les tiers sont
          susceptibles de traiter les Données Personnelles des Utilisateurs sont
          précisées dans la Politique de Confidentialité accessible
          <router-link to="/politique-de-confidentialite" class="text-fa-red"
            >ici.</router-link
          ></DoiText
        >
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >15. Droit à l’image</DoiHeader
        >
        <NumericList class="sm:ml-10 ml-5">
          <li>
            L’enregistrement vidéo des Sessions de sport étant l’une des
            caractéristiques des sites Fit Arena, l’Utilisateur reconnait être
            parfaitement informé de l’enregistrement de son image par des
            caméras dans l’enceinte de chaque site.
          </li>
          <br />
          <li>
            De même, l’Utilisateur, en cochant la case prévue à cet effet lors
            de la création de son compte utilisateur, reconnait et accepte
            donner expressément son autorisation et son consentement à
            l’enregistrement de son image :
          </li>
          <List class="sm:ml-10 ml-3">
            <HyphenListItem class="font-bold"
              >par voie de vidéo-surveillance pour des raisons de
              sécurité,</HyphenListItem
            >
            <HyphenListItem class="font-bold"
              >par l’enregistrement vidéo des Sessions de sport.</HyphenListItem
            ><br />
          </List>
          <li>
            La mise à disposition des vidéos des Sessions sur le Compte des
            Utilisateurs et la durée de conservation des vidéos par les
            responsables de traitement ne pourra excéder <b>un mois</b>, sauf
            accord contraire recueilli de l’Utilisateur.<br />
            Ce délai de conservation ne s’applique plus à toute vidéo
            téléchargée localement par le(s) Utilisateur(s) sur leur propre
            matériel, lorsque le contenu est partagé par l’Utilisateur au moins
            notamment des réseaux sociaux, ou lorsque l’Utilisateur souhaite
            conserver certains enregistrements sur son Compte.
          </li>
          <br />
          <li>
            L’Utilisateur reconnait et accepte que les Utilisateurs de la même
            Session ont accès à la vidéo de la Session via leurs Comptes
            Utilisateurs et ont la possibilité de la partager sur les réseaux
            sociaux.
          </li>
          <br />
          <li>
            L’Utilisateur reconnait et accepte que les vidéos prises par le
            système de vidéo surveillance sont utilisées à des fins de contrôle
            de la sécurité des Utilisateurs.
          </li>
        </NumericList>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >16. Propriété Intellectuelle</DoiHeader
        >
        <NumericList class="sm:ml-10 ml-5">
          <li>
            Le contenu du Site Internet et l’Application Fit Arena est la
            propriété de FIT ARENA et est protégé par les lois françaises et
            internationales relatives à la propriété intellectuelle.
          </li>
          <br />
          <li>
            Aucune disposition des présentes Conditions Générales ne peut être
            interprétée comme cédant ou transférant à l’Utilisateur tout ou
            partie des droits de propriété intellectuelle relatifs au Site
            Internet, à l’Application Fit Arena ou aux Services, à l’exception
            de la licence d’utilisation telle que précisée ci-après.
          </li>
          <br />
          <li>
            FIT ARENA concède à l’Utilisateur une licence d’utilisation limitée,
            non-exclusive, non transférable, non sous-licenciable sur
            l’Application Fit Arena et le Site Internet, dans le seul but de
            gérer ses réservations et d’accéder aux Services.
          </li>
          <br />
          <li>
            De manière générale, et sans que cette liste puisse être considérée
            comme exhaustive, l’Utilisateur s'engage à :
          </li>
          <List class="sm:ml-10 ml-3">
            <HyphenListItem
              >ne pas reproduire, copier, transférer, transmettre, résumer,
              intégrer, altérer, modifier, déplacer, retirer, remplacer ou
              rediffuser, sans autorisation écrite et préalable de FIT ARENA, de
              façon permanente ou provisoire, sur quelque support ou de quelque
              manière que ce soit, l’Application Fit Arena, le Site Internet ou
              le contenu de l’Application Fit Arena et le contenu du Site
              Internet et/ou le nom, le logo, la marque et/ou toute autre
              information ou signe distinctif se rapportant aux droits de FIT
              ARENA sur l’Application Fit Arena, le Site Internet ou les
              Services,</HyphenListItem
            ><br />
            <HyphenListItem
              >ne pas vendre, louer, sous-licencier ou distribuer de quelque
              façon que ce soit l’Application Fit Arena et/ou le Site
              Internet,</HyphenListItem
            ><br />
            <HyphenListItem
              >ne pas fusionner tout ou partie de l’Application Fit Arena et/ou
              du Site Internet dans d'autres programmes informatiques ou créer
              des œuvres dérivées à partir de l’Application Fit Arena et/ou du
              Site Internet,</HyphenListItem
            ><br />
            <HyphenListItem
              >ne pas adapter l’Application Fit Arena et/ou le Site Internet,
              les compiler, les décompiler, les désassembler, les traduire, les
              analyser, procéder à de l’ingénierie inverse ou toute autre
              opération destinée à accéder à tout ou partie des codes sources de
              l’Application Fit Arena et/ou du Site Internet ou tenter d'y
              procéder, sauf dans les limites autorisées par la
              loi,</HyphenListItem
            ><br />
            <HyphenListItem
              >ne pas extraire ou réutiliser, y compris à des fins privées, sans
              autorisation écrite et préalable de FIT ARENA, une partie
              substantielle ou non du contenu des bases de données et archives
              constituées par l’Application Fit Arena et/ou le Site
              Internet,</HyphenListItem
            ><br />
            <HyphenListItem
              >ne pas permettre, directement ou indirectement, par conseil, aide
              ou assistance, à un tiers quel qu’il soit, de réaliser l’un de ces
              actes ou de porter atteinte aux droits de propriété intellectuelle
              de FIT ARENA,</HyphenListItem
            ><br />
            <HyphenListItem
              >mettre en place des systèmes de contrôle adaptés en fonction des
              évolutions techniques pour éviter tout piratage des informations
              contenues dans l’Application Fit Arena et/ou dans le Site Internet
              et en particulier pour éviter l'utilisation illicite ou contraire
              aux présentes Conditions Générales de toute information ou Donnée
              de quelque manière et sous quelque forme que ce
              soit,</HyphenListItem
            ><br />
            <HyphenListItem
              >informer FIT ARENA dès qu’il a connaissance d'un piratage ou de
              toute utilisation illicite ou non conforme de l’Application Fit
              Arena ou du Site Internet.</HyphenListItem
            >
          </List>
          <br />
          <li>
            En outre, l’Utilisateur s'interdit de corriger par lui-même toute
            anomalie ou dysfonctionnement de l’Application Fit Arena ou du Site
            Internet de quelque nature que ce soit, FIT ARENA se réservant seul
            ce droit. FIT ARENA ne remet pas à l’Utilisateur les codes sources
            de l’Application Fit Arena et/ou du Site Internet, lesquels
            demeurent la propriété exclusive de FIT ARENA.
          </li>
        </NumericList>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >17. Durée - Résiliation</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          Les présentes Conditions Générales entrent en vigueur lors de leur
          acceptation par l’Utilisateur au moment de la création du Compte
          Utilisateur.
          <br /><br />
          Ces Conditions Générales restent en vigueur entre FIT ARENA et
          l’Utilisateur jusqu’à la clôture du Compte de l’Utilisateur.
          <br /><br />
          L’Utilisateur peut résilier la relation contractuelle à tout moment en
          clôturant son Compte dans les modalités ci-dessus.
          <br /><br />
          FIT ARENA peut résilier la relation contractuelle à tout moment par
          l’envoi d’un courrier électronique à l’adresse renseignée lors de la
          création de son Compte par l’Utilisateur en lui notifiant la
          résiliation, son motif et sa date d’effet. La résiliation prendra
          effet 15 jours à compter de l’envoi du courrier électronique de
          résiliation. Cette résiliation entraine la clôture du Compte de
          l’Utilisateur.
        </DoiText>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >18. Force Majeure</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          Les parties ne pourront être tenues pour responsables si la
          non-exécution ou le retard dans l'exécution de l'une quelconque de
          leurs obligations, telles que décrites dans les présentes découle d'un
          cas de force majeure, au sens de l'article 1218 du Code civil.
          <br /><br />
          La partie constatant l'événement devra sans délai et par tous moyens
          informer l'autre partie de son impossibilité à exécuter sa prestation
          et s'en justifier auprès de celle-ci. La suspension des obligations ne
          pourra en aucun cas être une cause de responsabilité pour
          non-exécution de l'obligation en cause, ni induire le versement de
          dommages et intérêts ou pénalités de retard.
        </DoiText>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >19. Droit applicable - Langue</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          Les présentes Conditions Générales et les opérations qui en découlent
          sont régies et soumises au droit français.
          <br /><br />
          Les présentes Conditions Générales sont rédigées en langue française.
          Dans le cas où elles seraient traduites en une ou plusieurs langues
          étrangères, seul le texte français ferait foi en cas de litige.
        </DoiText>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >20. Litige</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          Tous les litiges auxquels les opérations d'achat et de vente conclues
          en application des présentes Conditions Générales pourraient donner
          lieu, concernant tant leur validité, leur interprétation, leur
          exécution, leur résiliation, leurs conséquences et leurs suites et qui
          n'auraient pu être résolues entre FIT ARENA et l’Utilisateur seront
          soumis aux tribunaux compétents dans les conditions de droit commun.
          <br /><br />
          L’Utilisateur est informé qu'il peut en tout état de cause recourir à
          une médiation conventionnelle, notamment auprès de la Commission de la
          médiation de la consommation (article L.612-1 Code de la consommation)
          ou auprès des instances de médiation sectorielles existantes, et dont
          les références figurent sur le Site Internet ou à tout mode alternatif
          de règlement des différends (conciliation, par exemple) en cas de
          contestation.
        </DoiText>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >21. Dispositions diverses</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3"
          >Si l'une quelconque des dispositions des présentes Conditions
          Générales était déclarée nulle ou inapplicable en application d'une
          loi, d'un règlement ou à la suite d'une décision de justice devenue
          définitive, elle serait réputée non écrite et les autres dispositions
          resteraient en vigueur à condition que l’équilibre contractuel soit
          maintenu entre les parties.</DoiText
        >
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >22. Information Précontractuelle</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5"
          >L’Utilisateur reconnaît avoir eu communication, préalablement à sa
          réservation et à la conclusion du contrat, d'une manière lisible et
          compréhensible, des présentes Conditions Générales et Particulières
          applicables et de toutes les informations listées à l'article L. 221-5
          du Code de la consommation, et notamment les informations suivantes
          :</DoiText
        >
        <List class="sm:ml-10 ml-3">
          <HyphenListItem
            >les caractéristiques essentielles des Services, compte tenu du
            support de communication utilisé et du Service
            concerné,</HyphenListItem
          ><br />
          <HyphenListItem
            >le prix des Services et des frais annexes ,</HyphenListItem
          ><br />
          <HyphenListItem
            >en l'absence d'exécution immédiate du contrat, la date ou le délai
            auquel le FIT ARENA s'engage à fournir les Services
            commandés,</HyphenListItem
          ><br />
          <HyphenListItem
            >les informations relatives à l'identité de FIT ARENA, à ses
            coordonnées postales, téléphoniques et électroniques, et à ses
            activités, si elles ne ressortent pas du contexte,</HyphenListItem
          ><br />
          <HyphenListItem
            >les fonctionnalités du contenu numérique et, le cas échéant, à son
            interopérabilité,</HyphenListItem
          ><br />
          <HyphenListItem
            >la possibilité de recourir à une médiation conventionnelle en cas
            de litige,</HyphenListItem
          ><br />
          <HyphenListItem
            >les informations relatives au droit de rétractation, aux modalités
            de résiliation et autres conditions contractuelles
            importantes,</HyphenListItem
          ><br />
          <HyphenListItem>les moyens de paiement acceptés.</HyphenListItem>
        </List>
        <br />
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5"
          >Le fait pour une personne physique (ou morale), de réserver sur La
          Plateforme emporte adhésion et acceptation pleine et entière des
          présentes Conditions Générales et Particulières applicables et
          obligation de paiement des Services commandés, ce qui est expressément
          reconnu par l’Utilisateur, qui renonce, notamment, à se prévaloir de
          tout document contradictoire, qui serait inopposable à FIT
          ARENA.</DoiText
        >
        <br />
      </div>
    </section>
    <ShareSection />
  </div>
</template>

<script>
import ShareSection from "@/views/share/ShareSection";
import HyphenListItem from "@/components/atomic/HyphenListItem";
import List from "@/components/atomic/List";
import NumericList from "@/components/atomic/NumericList";
import BulletList from "@/components/atomic/BulletList";

export default {
  name: "Rgpd",
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Conditions générales d'utilisation et de service du site fit-arena.fr. Réservations des salles multisports connectées, intelligentes et autonomes. Partage d'expériences sportives et réservation en ligne",
      },
    ],
    link: [{ rel: "canonical", href: "https://www.fit-arena.fr/rgpd" }],
  },
  components: {
    ShareSection,
    HyphenListItem,
    List,
    NumericList,
    BulletList,
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.red {
  color: $doi--color-color0;
}

.font {
  font-family: $doi--text-font-family5;
}

.line {
  width: 80%;
}

.sub-title {
  text-transform: none;
}

@media (min-width: $doi-breakpoint-desktop) {
  .rgpd {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

@media (max-width: $doi-breakpoint-mobile) {
  .rgpd {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .title {
    font-size: $doi--text-font-size5;
  }

  .sub-title {
    font-size: $doi--text-font-size4;
  }
}
</style>
