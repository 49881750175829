<template>
  <div class="font">
    <section class="container mx-auto text-justify mt-4">
      <div class="flex justify-center mb-4">
        <span class="flex flex-1 items-center"><DoiLine class="line" /></span>
        <DoiHeader
          class="flex text-center title"
          :level="1"
          :headerColor="1"
          :fontFamily="0"
          >POLITIQUE DE CONFIDENTIALITÉ ET GESTION DES COOKIES</DoiHeader
        >
        <span class="flex flex-1"></span>
      </div>
      <div class="pc 2k:text-xl 4k:text-4xl">
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          FIT ARENA, société par actions simplifiée, immatriculée au Registre du
          Commerce et des Sociétés de Lyon sous le numéro 830 754 933, dont le
          siège social est sis 250 Rue du petit bois, Parc des Platières, 69440
          Mornant, France, est soucieuse de la protection des données
          personnelles des internautes (ci-après l’« <b>Utilisateur</b> ») de
          son site internet www.fit-arena.fr et de son application (ci-après la
          « <b>Plateforme</b> »). <br /><br />
          En se rendant sur la Plateforme, l’Utilisateur est susceptible de
          communiquer des Données Personnelles. FIT ARENA, dans le cadre de la
          collecte et du traitement des Données Personnelles sur la Plateforme
          et du parcours d’inscription de l’Utilisateur, intervient en qualité
          de Responsable de traitement, et s’engage à protéger ces données en
          conformité avec la réglementation applicable et notamment le Règlement
          (UE) n°2016/679 du 27 avril 2016 dit « Règlement Général sur la
          Protection des Données » ou « RGPD » et la loi n° 78-17 du 6 janvier
          1978 dite « Loi Informatique et Libertés » modifiée (ci-après la «
          <b>Réglementation applicable</b> »). <br /><br />
          FIT ARENA a désigné [...] comme délégué à la protection des données
          (DPO).
        </DoiText>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >1. Définitions</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5"
          >Les mots et expressions utilisés dans la présente politique de
          confidentialité (ci-après « <b>Politique de Confidentialité</b> ») ont
          le sens qui leur est donné par la Réglementation applicable :</DoiText
        ><br />
        <List class="sm:ml-10 ml-5">
          <HyphenListItem
            ><b>Destinataire</b> : désigne la personne physique ou morale,
            l’autorité publique, le service ou tout autre organisme qui reçoit
            communication de données à caractère personnel, qu’il s’agisse ou
            non d’un tiers (...) ;</HyphenListItem
          ><br />
          <HyphenListItem
            ><b>Donnée Personnelle</b> : désigne toute information se rapportant
            à une personne physique directement ou indirectement identifiée ou
            identifiable, notamment par référence à un identifiant, tel qu’un
            nom, un numéro d’identification, des données de localisation, un
            identifiant en ligne, ou à un ou plusieurs éléments spécifiques
            propres à son identité physique, physiologique, génétique,
            psychique, économique, culturelle ou sociale ;</HyphenListItem
          ><br />
          <HyphenListItem
            ><b>Responsable de traitement</b> : désigne la personne physique ou
            morale, l’autorité publique, le service ou un autre organisme qui,
            seul ou conjointement avec d’autres, détermine les finalités et les
            moyens du traitement ;</HyphenListItem
          ><br />
          <HyphenListItem
            ><b>Sous-traitant</b> : désigne la personne physique ou morale,
            l’autorité publique, le service ou un autre organisme qui traite des
            données personnelles pour le compte du responsable du traitement ou
            du sous-traitant initial ;</HyphenListItem
          ><br />
          <HyphenListItem
            ><b>Traitement</b> : désigne toute opération ou tout ensemble
            d’opérations effectuées ou non à l’aide de procédés automatisés et
            appliquées à des données ou des ensembles de données personnelles,
            tels que la collecte, l’enregistrement, l’organisation, la
            structuration, la conservation, l’adaptation ou la modification,
            l’extraction, la consultation, l’utilisation, la communication par
            transmission, la diffusion ou toute autre forme de mise à
            disposition, le rapprochement ou l’interconnexion, la limitation,
            l’effacement ou la destruction.</HyphenListItem
          >
        </List>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >2. Données Personnelles collectées</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5"
          >FIT ARENA collecte, par l'utilisation de la Plateforme par tout
          Utilisateur, les données suivantes concernant l'Utilisateur :</DoiText
        ><br />
        <List class="sm:ml-10 ml-5">
          <HyphenListItem
            >Données relatives à l'identification de l'Utilisateur pour la
            création et la gestion de son compte</HyphenListItem
          >
          <BulletList class="sm:ml-10 ml-14 4k:ml-20">
            <li>Nom et prénom *</li>
            <li>Adresse e-mail *</li>
            <li>Numéro de téléphone</li>
            <li>Pseudo</li>
            <li>Photographie (facultatif)</li>
          </BulletList>
          <br />
          <HyphenListItem
            >Données relatives à l’identification de l’Utilisateur pour la
            réservation et l’utilisation de la salle</HyphenListItem
          >
          <BulletList class="sm:ml-10 ml-14 4k:ml-20">
            <li>Géolocalisation</li>
            <li>
              QR code à usage unique généré suite à la réservation de la salle
              et permettant l’identification de l’Utilisateur
            </li>
            <li>Historique des réservations</li>
          </BulletList>
          <br />
          <HyphenListItem
            >Données relatives à l'expérience de jeu de
            l’Utilisateur</HyphenListItem
          >
          <BulletList class="sm:ml-10 ml-14 4k:ml-20">
            <li>Historique de jeu</li>
            <li>Scores</li>
            <li>
              Images de vidéo exploitation des pratiquants : chaque partie est
              captée via un dispositif de vidéosurveillance afin de permettre
              l’arbitrage et le visionnage des moments clefs de la partie sur
              écran présent dans chaque fit arena ;<br />
              L’enregistrement de la partie : l’Utilisateur peut visionner la
              partie en replay et l’enregistrer localement, enregistrer et
              partager ses scores, ou encore comparer ses performances
            </li>
          </BulletList>
          <br />
          <HyphenListItem
            >Données relatives à l’envoi de chasubles floqués gratuitement le
            cas échéant, selon éligibilité définie dans les conditions générales
            d’utilisation de la Plateforme</HyphenListItem
          >
          <BulletList class="sm:ml-10 ml-14 4k:ml-20">
            <li>Adresse postale *</li>
          </BulletList>
          <br />
          <HyphenListItem
            >Données relatives à la sécurité des Utilisateurs et au mouvement
            des infrastructures de la salle</HyphenListItem
          >
          <BulletList class="sm:ml-10 ml-14 4k:ml-20">
            <li>
              Certaines zones spécifiques de la salle font l’objet d’un
              dispositif de vidéosurveillance, ce dont il résulte le traitement
              des données d’Utilisateurs susceptibles d’être filmés.
            </li>
          </BulletList>
        </List>
        <br />
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5"
          >Pour l’usage de certaines fonctionnalités de la Plateforme, notamment
          le module de paiement, FIT ARENA sous-traite le service de paiement à
          (à compléter) qui collecte et traite les Données Personnelles de
          l’Utilisateur suivantes :</DoiText
        ><br />
        <List class="sm:ml-10 ml-5">
          <HyphenListItem>Données de paiement de l'Utilisateur</HyphenListItem>
          <BulletList class="sm:ml-10 ml-14 4k:ml-20">
            <li>Coordonnées bancaires</li>
            <li>Récapitulatif des achats</li>
          </BulletList>
        </List>
        <br />
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5"
          >Les conditions générales applicables au service de paiement sont
          acessibles <a href="/rgpd" class="red">ici.</a></DoiText
        >
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >3. Bases légales et finalités du traitement</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5"
          >FIT ARENA collecte les Données Personnelles des Utilisateurs sur la
          base du consentement de l’Utilisateur, pour les besoins de son
          activité et l’exécution du contrat, le respect d’une obligation légale
          ou réglementaire et l’intérêt légitime, et notamment aux fins
          suivantes :</DoiText
        ><br />
        <List class="sm:ml-10 ml-5">
          <HyphenListItem
            >lorsque l’Utilisateur souhaite créer un compte ;</HyphenListItem
          ><br />
          <HyphenListItem
            >afin d’authentifier l’Utilisateur pour accéder à son compte et
            utiliser la Plateforme ;</HyphenListItem
          ><br />
          <HyphenListItem
            >lorsque l’Utilisateur souhaite réserver la salle pour la pratique
            d’un sport via la Plateforme ;</HyphenListItem
          ><br />
          <HyphenListItem
            >afin de permettre l’accès de la salle à l’Utilisateur, programmée
            pour le sport présélectionné par l’Utilisateur ;</HyphenListItem
          ><br />
          <HyphenListItem
            >afin d’analyser les données de l’Utilisateur et fournir des
            recommandations d’activités ou de produits ;</HyphenListItem
          ><br />
          <HyphenListItem
            >afin de garantir à l’Utilisateur une expérience de jeu optimale et
            un suivi de ses performances ;</HyphenListItem
          ><br />
          <HyphenListItem
            >afin de garantir la sécurité de l’Utilisateur.</HyphenListItem
          >
        </List>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >4. Durée de conservation</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          FIT ARENA ne conserve les Données Personnelles des Utilisateurs que
          pendant le temps nécessaire aux opérations pour lesquelles elles ont
          été collectées et dans le respect de la réglementation en vigueur.
          <br /><br />
          Détail des durées de conservation des Données Personnelles ou renvoi à
          un tableau précisant les différentes durées. </DoiText
        ><br />
        <DoiTable class="data-conservation">
          <template slot="thead">
            <th>Traitement</th>
            <th>Durée de conservation</th>
          </template>
          <template slot="tbody1">
            <td>Images des caméras de vidéoprotection</td>
            <td>...</td>
          </template>
          <template slot="tbody2">
            <td>Données de connexion au compte utilisateur</td>
            <td>Toute la durée d'activité du compte + 3 ans</td>
          </template>
          <template slot="tbody3">
            <td>Cookies</td>
            <td>6 mois</td>
          </template>
        </DoiTable>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >5. Destinataires</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5"
          >Dans le cadre d’une politique stricte de gestion des accès et de la
          confidentialité, seuls les destinataires dûment habilités par FIT
          ARENA peuvent accéder aux informations que l’Utilisateur a pu
          communiquer.</DoiText
        ><br />
        <NumericList class="sm:ml-10 ml-5">
          <DoiHeader :level="3" class="underline header3"
            >1. Destinataires internes</DoiHeader
          ><br />
          <DoiText :textColor="1" :fontSize="3" :fontFamily="5"
            >Les Données Personnelles collectées pourront être utilisées au sein
            des services de FIT ARENA suivants : direction, service commercial,
            service technique, service informatique, service marketing, service
            RH ...</DoiText
          ><br />
          <DoiHeader :level="3" class="underline header3"
            >2. Sous-traitants et prestataires externes</DoiHeader
          ><br />
          <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
            Les Données Personnelles collectées peuvent également être
            transmises à des sous-traitants de FIT ARENA, dans les limites
            prévues par les dispositions en vigueur et conformément à la
            présente Politique de Confidentialité.
            <br /><br />
            Les Données Personnelles collectées peuvent être transmises à des
            prestataires externes intervenants sur la Plateforme afin de
            garantir une expérience optimale aux Utilisateurs.
            <br /><br />
            Les Données Personnelles collectées peuvent être transmises à des
            prestataires externes qui les traitent pour le compte de FIT ARENA,
            selon ses instructions, notamment dans le cadre du paiement en
            ligne, de la sécurité ou encore dans le cadre de statistiques et
            d’enquêtes.
            <br /><br />
            La transmission des données des personnes concernées aux
            sous-traitants et prestataires externes de FIT ARENA est encadrée
            afin de garantir la sécurité des données personnelles.
            <br /><br />
            Veuillez trouver la liste tenue à jour de ces sous-traitants et
            prestataires externes <a href="/" class="red">ici.</a> </DoiText
          ><br />
          <DoiHeader :level="3" class="underline header3"
            >3. Gestionnaire de site, organisme sportif et animateur</DoiHeader
          ><br />
          <DoiHeader :level="4" class="underline header4"
            >Gestionnaire de la fit arena sélectionnée</DoiHeader
          >
          <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
            Conformément aux conditions générales d’utilisation applicables,
            chaque fit arena est exploitée par une collectivité locale ou une
            entreprise privée qui assume la responsabilité de l’installation.
            <br /><br />
            A cet égard, lorsque l’Utilisateur utilise la Plateforme pour
            réserver une activité sportive auprès d’un fit arena spécialement
            identifiée, les Données Personnelles collectées pour les besoins de
            cette réservation sont transmises au gestionnaire du site concerné
            (ci-après désigné le « <b>Gestionnaire de Site</b> »). <br /><br />
            Chaque page dédiée à une fit arena au sein de la Plateforme précise
            la collectivité ou l’entreprise privée gestionnaire de la fit arena
            sélectionnée. </DoiText
          ><br />
          <DoiHeader :level="4" class="underline header4"
            >Organisme sportif</DoiHeader
          >
          <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
            Conformément aux conditions générales d’utilisation applicables,
            chaque Utilisateur de la Plateforme peut, dans les conditions
            prévues par le Gestionnaire du Site, s’inscrire à des sessions
            d’activité sportive attribuée à un organisme sportif spécifique
            (ci-après désigné « <b>Organisme Sportif</b> »). <br /><br />
            Dans ce cas, seul les Utilisateurs éligibles à l’inscription de ces
            sessions pourront s’enregistrer. Si l’Utilisateur rempli les
            conditions d’éligibilité, les Données Personnelles relatives à son
            inscription seront également transmises à l’Organisme Sportif.
            <br /><br />
            L’Organisme Sportif peut également avoir été à l’origine de
            l’inscription de l’Utilisateur sur la Plateforme. Dans le cas d’une
            adhésion à un Organisme Sportif qui utilise la Plateforme pour la
            gestion de ses sessions sportives en fit arena, l’Organisme Sportif
            a collecté les Données Personnelles de l’Utilisateur afin notamment
            de l’inscrire sur la Plateforme.
            <br /><br />
            Dans ce cas, l’Organisme Sportif est responsable du traitement
            initial des Données Personnelles collectées et garantit à FIT ARENA
            qu’il a fourni toutes les informations utiles à l’Utilisateur
            préalablement à son inscription sur la Plateforme.
            <br /><br />
          </DoiText>
          <DoiHeader :level="4" class="underline header4">Animateur</DoiHeader>
          <DoiText :textColor="1" :fontSize="3" :fontFamily="5"
            >Selon le fonctionnement de chaque Organisme Sportif, notamment
            selon les droits d’accès à la Plateforme concédés par l’Organisme
            Sportif aux animateurs intervenant pour son compte pour l’animation
            de sessions d’activités sportives en fit arena (ci-après désigné l’
            « <b>Animateur</b> »), certaines Données Personnelles d’inscription
            de l’Utilisateur à une session pourront être transmises à
            l’Animateur.</DoiText
          ><br />
          <DoiHeader :level="3" class="underline header3"
            >4. Transmission anonymisée</DoiHeader
          ><br />
          <DoiText :textColor="1" :fontSize="3" :fontFamily="5"
            >FIT ARENA pourra partager avec des tiers autres que ceux
            identifiées des données anonymisées ou agrégées, à des fins
            statistiques, sans qu’il ne soit possible pour ces tiers
            d’identifier la personne concernée de quelque façon que ce
            soit.</DoiText
          >
        </NumericList>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >6. Hébergement des données</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          Le traitement et l’hébergement des Données Personnelles sont
          exclusivement effectués au sein de l’Union Européenne.
          <br /><br />
          Néanmoins, si FIT ARENA était amenée à transférer des Données
          Personnelles hors du territoire de l’Union européenne, FIT ARENA
          garantit que ces transferts seront exécutés vers des Etats qui font
          l’objet d’une décision d’adéquation par la Commission européenne,
          justifiant d’un niveau de protection adéquat, au sens de l’article 45
          du Règlement général européen 2016/679 du 27 avril 2016 sur la
          protection des données personnelles.
          <br /><br />
          A défaut de décision d’adéquation, FIT ARENA pourra transférer des
          Données Personnelles hors de l’Union européenne à des Sous-traitants,
          dans les conditions prévues à l’article 46 du Règlement général
          européen 2016/679 du 27 avril 2016 sur la protection des données
          personnelles, notamment par la signature de clauses contractuelles
          types de transfert approuvées par la Commission européenne.
        </DoiText>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >7. Mesures de sécurité mises en &oelig;uvre</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          FIT ARENA s’engage à assurer la sécurité et l’intégrité des Données
          Personnelles de l’Utilisateur.
          <br /><br />
          A ce titre, FIT ARENA met en œuvre et maintien des mesures techniques
          et organisationnelles de sécurité de la Plateforme et de son système
          d’information adaptées au regard de la nature de des Données
          Personnelles et des risques présentés par leur traitement. </DoiText
        ><br />
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5"
          >Ces mesures visent à :</DoiText
        >
        <BulletList class="sm:ml-10 ml-5">
          <li>
            protéger les Données Personnelles de l’Utilisateur contre leur
            destruction, perte, altération, divulgation à des tiers non
            autorisés,
          </li>
          <li>
            assurer le rétablissement de la disponibilité des Données
            Personnelles de l’Utilisateur et l’accès à celles-ci dans des délais
            appropriés en cas d’incident physique ou technique.
          </li>
        </BulletList>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >8. Droits de l'Utilisateur</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          Conformément à la Réglementation applicable, l'Utilisateur dispose des
          droits ci-après énumérés.
          <br /><br />
          Afin que le Responsable du traitement des Données Personnelles puisse
          faire droit à sa demande, l'Utilisateur est tenu de lui communiquer :
          ses prénom et nom, son adresse e-mail, son numéro de téléphone, un
          justificatif d’identité, l’objet de sa demande.
          <br /><br />
          Le Responsable du traitement des Données Personnelles est tenu de
          répondre à l'Utilisateur dans un délai de 30 (trente) jours maximum,
          sauf le cas où un nombre important de demandes est simultanément
          opéré, ou encore le cas où les recherches des informations nécessitent
          un délai supplémentaire. Dans ce cas, le Responsable de traitement
          informera l’Utilisateur de cette impossibilité de respecter le délai
          de 30 jours et lui communiquera le délai nécessaire, qui ne pourra pas
          excéder trois (3) mois. </DoiText
        ><br />
        <List class="sm:ml-10 ml-5">
          <HyphenListItem
            >Droit à l’information : le droit pour l’Utilisateur d’avoir une
            information claire, précise et complète sur l’utilisation des
            Données Personnelles par FIT ARENA ;</HyphenListItem
          ><br />
          <HyphenListItem
            >Droit d’accès : le droit d’obtenir une copie des Données
            Personnelles que le(s) Responsable(s) de traitement détient sur
            l’Utilisateur ;</HyphenListItem
          ><br />
          <HyphenListItem
            >Droit de rectification : le droit pour l’Utilisateur de faire
            rectifier ses Données Personnelles si elles sont inexactes ou
            obsolètes et/ou de les compléter si elles sont incomplètes
            ;</HyphenListItem
          ><br />
          <HyphenListItem
            >Droit à l’effacement (« droit à l’oubli ») : droit reconnu à
            l’Utilisateur, dans certaines conditions, de faire effacer ou
            supprimer ses Données Personnelles, à moins que FIT ARENA ait un
            intérêt légitime à les conserver ;</HyphenListItem
          ><br />
          <HyphenListItem
            >Droit à la limitation du traitement : l’Utilisateur a le droit,
            dans certaines conditions, de demander la suspension momentanée du
            Traitement de ses Données Personnelles ;</HyphenListItem
          ><br />
          <HyphenListItem
            >Droit à la portabilité : l’Utilisateur a le droit de demander à ce
            que ses Données Personnelles soient transmises dans un format
            réexploitable permettant de les utiliser dans une autre base de
            Données Personnelles ;</HyphenListItem
          ><br />
          <HyphenListItem
            >Droit d’opposition : le droit pour l’Utilisateur de s’opposer au
            traitement de ses Données Personnelles par FIT ARENA pour des
            raisons tenant à une situation particulière (sous conditions)
            ;</HyphenListItem
          ><br />
          <HyphenListItem
            >Retrait du consentement : l’Utilisateur peut retirer son
            consentement au traitement futur de ses Données Personnelles par FIT
            ARENA, lorsque que le Traitement est fondé sur le consentement
            ;</HyphenListItem
          ><br />
          <HyphenListItem
            >Droit de ne pas faire l’objet d’une décision fondée exclusivement
            sur un procédé automatisé : l'Utilisateur a le droit de ne pas faire
            l'objet d'une décision fondée exclusivement sur un procédé
            automatisé si la décision produit des effets juridiques le
            concernant, ou l'affecte de manière significative de façon
            similaire. Dans le cas où au moins un procédé automatisé est
            nécessaire à l’exécution de la demande, ou d’une prestation de
            services, il est alors possible que FIT ARENA ne soit pas en mesure
            de fournir tout ou partie de la prestation, et dans ce cas,
            l’Utilisateur reconnaît expressément qu’il demande l’annulation de
            la prestation de services.</HyphenListItem
          ><br />
          <HyphenListItem
            >Droit d’introduire une réclamation : droit pour l’Utilisateur
            d’introduire une réclamation auprès de la Commission Nationale de
            l’Informatique et des Libertés (CNIL) s’il considère que le
            Traitement opéré par FIT ARENA constitue une violation de ses
            Données Personnelles.</HyphenListItem
          >
        </List>
        <br />
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5"
          >L’Utilisateur peut exercer vos droits à tout moment auprès du délégué
          à la protection des données (DPO) de FIT ARENA, par email à l’adresse
          suivante : [...].</DoiText
        >
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >9. Gestion des cookies</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          Le terme de cookies désigne l'ensemble des fichiers stockés sur un
          ordinateur ou téléphone d’un internaute, utilisés pour faire
          fonctionner les sites Internet et/ou pour collecter des informations
          sur la navigation dudit internaute.
          <br /><br />
          Certains de ces cookies sont nécessaires pour accéder à certaines
          fonctionnalités d’un site. D’autres cookies sont d’utilité pratique
          pour l’internaute (sauvegarde sécurisée d’un nom d’utilisateur et d’un
          mot de passe notamment).
          <br /><br />
          Pour être utilisés, certains cookies nécessitent le consentement
          préalable de l’Utilisateur, d’autres non.
          <br /><br />
          Les cookies exemptés de consentement préalable sont ceux qui sont
          strictement nécessaires à la fourniture d’un service expressément
          demandé par l’Utilisateur.
          <br /><br />
          Les cookies nécessitant une information préalable et une demande de
          consentement, sont, par exemple : </DoiText
        ><br />
        <List class="sm:ml-10 ml-5">
          <HyphenListItem
            >les cookies liés aux opérations relatives à la publicité
            ;</HyphenListItem
          ><br />
          <HyphenListItem
            >les cookies des réseaux sociaux générés par les boutons de partage
            de réseaux sociaux lorsqu'ils collectent des données personnelles
            sans consentement des personnes concernées ;</HyphenListItem
          ><br />
          <HyphenListItem
            >certains cookies de mesure d'audience.</HyphenListItem
          >
        </List>
        <br />
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          Les cookies de fonctionnement du Site sont strictement nécessaires au
          bon fonctionnement du Site.
          <br /><br />
          A partir de l’onglet « paramétrer mes choix » présent dans le bandeau
          cookies, vous pouvez bloquer l’utilisation de certains cookies, tout
          en sachant que certaines fonctionnalités de la Plateforme pourront ne
          plus être accessibles.
          <br /><br />
          Les cookies que nous utilisons sur notre site internet sont les
          suivants : </DoiText
        ><br />
        <DoiTable class="cookies">
          <template slot="thead">
            <th>Cookie posé par</th>
            <th>Nom du cookie</th>
            <th>Finalité du cookie</th>
            <th>Type</th>
            <th>Durée de conservation</th>
          </template>
          <template slot="tbody1">
            <td>...</td>
            <td>...</td>
            <td>...</td>
            <td>...</td>
            <td>...</td>
          </template>
        </DoiTable>
        <br />
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          Vous pouvez facilement désactiver et/ou effacer les cookies de votre
          ordinateur, de votre tablette ou de votre mobile grâce à la gestion
          des paramètres de votre navigateur.
          <br /><br />
          Afin de gérer les cookies au plus près de vos attentes, FIT ARENA vous
          invite à paramétrer votre navigateur en tenant compte de la finalité
          des cookies.
        </DoiText>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >10. Modification de la Politique</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          La présente Politique de Confidentialité peut être modifiée ou
          aménagée à tout moment en cas d’évolution légale, jurisprudentielle,
          des décisions et recommandations de la Commission européenne, du
          Comité européen de protection des données, de la CNIL ou des usages.
          <br /><br />
          Toute nouvelle version de la présente politique sera portée à la
          connaissance des Utilisateurs par tout moyen en ce compris la voie
          électronique (diffusion par courrier électronique ou en ligne par
          exemple). </DoiText
        ><br />
      </div>
    </section>
    <ShareSection />
  </div>
</template>

<script>
import HyphenListItem from "@/components/atomic/HyphenListItem";
import List from "@/components/atomic/List";
import NumericList from "@/components/atomic/NumericList";
import BulletList from "@/components/atomic/BulletList";
import ShareSection from "@/views/share/ShareSection";

export default {
  name: "PolitiqueDeConfidentialite",
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Politique de confidentialité du site fit-arena.fr. Réservations des salles multisports connectée, intelligente et autonome. Partage d'expériences sportives et réservation en ligne",
      },
    ],
    link: [
      {
        rel: "canonical",
        href: "https://www.fit-arena.fr/politique-de-confidentialite",
      },
    ],
  },
  components: {
    ShareSection,
    HyphenListItem,
    List,
    NumericList,
    BulletList,
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.red {
  color: $doi--color-color0;
}

.font {
  font-family: $doi--text-font-family5;
}

.line {
  width: 80%;
}

.sub-title {
  text-transform: none;
}

@media (min-width: $doi-breakpoint-desktop) {
  .pc {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

@media (max-width: $doi-breakpoint-tablet) {
  table.data-conservation td:nth-of-type(1):before {
    content: "Traitement";
  }
  table.data-conservation td:nth-of-type(2):before {
    content: "Durée de conservation";
  }
}

@media (max-width: $doi-breakpoint-tablet) {
  table.cookies td:nth-of-type(1):before {
    content: "Cookie posé par";
  }
  table.cookies td:nth-of-type(2):before {
    content: "Nom du cookie";
  }
  table.cookies td:nth-of-type(3):before {
    content: "Finalité du cookie";
  }
  table.cookies td:nth-of-type(4):before {
    content: "Type";
  }
  table.cookies td:nth-of-type(5):before {
    content: "Durée de conservation";
  }
}

@media (max-width: $doi-breakpoint-mobile) {
  .pc {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .title {
    font-size: $doi--text-font-size5;
  }

  .sub-title {
    font-size: $doi--text-font-size4;
  }
}
</style>
