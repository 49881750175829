<template>
  <ul class="list-decimal list-outside">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  name: "NumericList",
};
</script>
