<template>
  <div class="font">
    <section class="container mx-auto text-justify mt-4">
      <div class="flex justify-center mb-4">
        <span class="flex flex-1 items-center"><DoiLine class="line" /></span>
        <DoiHeader
          class="flex text-center title"
          :level="1"
          :headerColor="1"
          :fontFamily="0"
          >MENTIONS LÉGALES</DoiHeader
        >
        <span class="flex flex-1"></span>
      </div>
      <div class="m-l 2k:text-xl 4k:text-4xl">
        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >Editeur de la plateforme</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          Le site accessible depuis l’URL www.fit-arena.fr et l’application Fit
          Arena (ci-après la « Plateforme »), sont édités par la société FIT
          ARENA, société par actions simplifiée au capital de 25.000 euros,
          immatriculée au Registre du Commerce et des Sociétés de Lyon sous le
          numéro 830 754 933, et dont le siège social est situé 250 Rue du petit
          bois, Parc d’activités des Platières, 69440 Mornant, France (ci-après
          désignée « FIT ARENA »).
          <br /><br />
          Numéro TVA Intracommunautaire : FR64 830 754 933
          <br /><br />
          Vous pouvez nous contacter par mail : [...] ou par téléphone : [...]
        </DoiText>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >Directeur de la publication</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5"
          >(représentant légal de la société)</DoiText
        >
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >Développement de la plateforme</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          Société DOING<br />
          Le Rocacier, 15 rue Camille de Rochetaillée,<br />
          42000 SAINT-ETIENNE - FRANCE<br /><br />
          https://www.doing.fr/
        </DoiText>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >Hébergement de la plateforme</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          Société DOING<br />
          Le Rocacier, 15 rue Camille de Rochetaillée,<br />
          42000 SAINT-ETIENNE - FRANCE<br /><br />
          https://www.doing.fr/
        </DoiText>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >Disponibilité de la plateforme</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          FIT ARENA déclare tout mettre en œuvre pour assurer aux utilisateurs
          de la Plateforme un accès optimal à la Plateforme sauf cas de force
          majeure, évènement hors de son contrôle et sous réserve des périodes
          de maintenance et des pannes éventuelles.
          <br /><br />
          En conséquence, la responsabilité de FIT ARENA ne pourra être
          recherchée en raison des problèmes de connexion propres au matériel
          informatique de l’utilisateur de la Plateforme ou liés à une
          défaillance du fournisseur d’accès à Internet utilisé.
        </DoiText>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >Protection des données personnelles</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          FIT ARENA est soucieuse de la protection des données personnelles des
          utilisateurs de la Plateforme et s’engage à les protéger en conformité
          avec la réglementation applicable et notamment le Règlement (UE)
          n°2016/679 du 27 avril 2016 dit « Règlement Général sur la Protection
          des Données » ou « RGPD » et la loi n° 78-17 du 6 janvier 1978 dite «
          Loi Informatique et Liberté » modifiée.
          <br /><br />
          Retrouvez l’intégralité de la Politique de confidentialité et de
          gestion des cookies
          <router-link to="/politique-de-confidentialite" class="red"
            >ici.</router-link
          ></DoiText
        >
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >Crédits</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5"
          >Les photos et vidéos présentes sur la Plateforme sont réalisées par
          [...]</DoiText
        >
        <br />
      </div>
    </section>
    <ShareSection />
  </div>
</template>

<script>
import ShareSection from "@/views/share/ShareSection";

export default {
  name: "MentionsLegales",
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Mentions Légales du site fit-arena.fr. Réservations des salles multisports connectées, intelligentes et autonomes. Partage d'expériences sportives et réservation en ligne",
      },
    ],
    link: [
      { rel: "canonical", href: "https://www.fit-arena.fr/mentions-legales" },
    ],
  },
  components: {
    ShareSection,
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.red {
  color: $doi--color-color0;
}

.font {
  font-family: $doi--text-font-family5;
}

.line {
  width: 80%;
}

.sub-title {
  text-transform: none;
}

@media (min-width: $doi-breakpoint-desktop) {
  .m-l {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

@media (max-width: $doi-breakpoint-mobile) {
  .m-l {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .title {
    font-size: $doi--text-font-size5;
  }

  .sub-title {
    font-size: $doi--text-font-size4;
  }
}
</style>
