<template>
  <div class="font">
    <section class="container mx-auto text-justify mt-4">
      <div class="flex justify-center mb-4">
        <span class="flex flex-1 items-center"><DoiLine class="line" /></span>
        <DoiHeader
          class="flex text-center title"
          :level="1"
          :headerColor="1"
          :fontFamily="0"
          >FOIRE AUX QUESTIONS</DoiHeader
        >
        <span class="flex flex-1"></span>
      </div>
      <div class="m-l 2k:text-xl 4k:text-4xl">
        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >Comment je réserve mon créneau ?</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          Pour réserver votre créneau, rien de plus simple : rendez-vous sur
          l'application Fit Arena ou sur le site
          <router-link to="/" class="red">www.fit-arena.fr</router-link> !
        </DoiText>
        <br />
        <NumericList class="sm:ml-10 ml-5">
          <li>Connectez-vous ou créez votre compte.</li>
          <li>Sélectionnez la Fit Arena de votre choix.</li>
          <li>Choisissez le sport que vous souhaitez pratiquer.</li>
          <li>
            Cliquez sur la date et l'heure souhaitées parmi les créneaux
            disponibles.
          </li>
          <li>Validez votre panier puis suivez les étapes de paiement.</li>
          <li>
            Invitez vos amis ou votre famille à vous rejoindre en leur envoyant
            le lien d'invitation.
          </li>
        </NumericList>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >Comment inviter mes amis ?</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          Une fois votre créneau réservé, vous pourrez partager un lien
          d'invitation aux personnes de votre choix. Pour confirmer leur
          participation, ces derniers devront se connecter à leur compte ou s'en
          créer un puis ajouter leurs coordonnées bancaires. Un QR code sera
          généré pour chaque participant afin de permettre une entrée en toute
          autonomie pour chacun.
        </DoiText>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >Suis-je obligé de télécharger l'application pour réserver un créneau
          ?</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          La réservation de votre créneau est également réalisable sur le site
          internet Fit Arena
          <router-link to="/" class="red">www.fit-arena.fr</router-link>. Le
          process à suivre est le même que celui de l'application.
        </DoiText>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >Comment faire si je ne peux pas me rendre à la séance réservée
          ?</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          Le montant de la séance est partagé entre les participants et vous ne
          serez débités qu'une fois la séance de sport terminée. Ainsi, en cas
          d'indisponibilité et d'annulation de votre part dans le délai imparti,
          vous n'aurez aucun frais.
        </DoiText>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >À quoi sert le replay ?</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          Lors de votre séance, vous pouvez enregistrer les temps forts de
          votre/vos match(s) depuis la tablette tactile grâce aux caméras
          présentes sur le terrain. Vous pouvez ensuite les consulter depuis le
          corner après-match mais également au sein de votre compte Fit Arena,
          dans la section replay. Vous pouvez ainsi voir et revoir vos belles
          actions et les partager sur les réseaux sociaux.
        </DoiText>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >À quoi servent les tablettes tactiles sur le terrain ?</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          La tablette tactile, présente sur chaque terrain, intervient à
          différents moments de votre séance :
        </DoiText>
        <br />
        <BulletList class="sm:ml-10 ml-5">
          <li>
            Au début de la séance pour composer les équipes et lancer la partie
          </li>
          <li>
            Pendant vos matchs pour mettre à jour les scores qui sont retransmis
            sur écran géant
          </li>
          <li>
            Au moment de vos plus belles actions pour enregistrer vos temps
            forts
          </li>
        </BulletList>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >Comment annuler ma participation ?</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          Dans un délai de 48h avant le jour de la séance de sport, vous pouvez
          annuler sans contrainte votre réservation. Pour ce faire, vous devez
          vous connecter à votre compte Fit arena depuis l'application ou le
          site <router-link to="/" class="red">www.fit-arena.fr</router-link>.
          Rendez-vous ensuite dans l'onglet « Mes Réservations » pour procéder à
          l'annulation de la séance.
        </DoiText>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >Où retrouver l'historique de mes parties ?</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          Vous pouvez aisément retrouver l'historique de vos parties au sein de
          votre compte Fit Arena, sur l'application ou le site internet
          <router-link to="/" class="red">www.fit-arena.fr</router-link>. Dans
          l'onglet « Mes scores & replays », vous retrouvez les vidéos de vos
          temps forts enregistrés ainsi que les scores des parties effectuées.
        </DoiText>
        <br /><br />

        <DoiHeader
          :level="2"
          border
          :headerColor="0"
          class="text-2xl mb-2 4k:text-5xl 4k:mb-10 sub-title"
          >Je ne retrouve pas mon QR code pour la prochaine séance.</DoiHeader
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="5">
          Au sein de votre compte Fit Arena (accessible depuis l'application et
          le site internet
          <router-link to="/" class="red">www.fit-arena.fr</router-link>), un
          onglet « Mes Réservations » est disponible. Vous y retrouvez toutes
          les réservations effectuées, avec le QR code d'accès attribué.
        </DoiText>
        <br /><br />
      </div>
    </section>
    <ShareSection />
  </div>
</template>

<script>
import NumericList from "@/components/atomic/NumericList";
import BulletList from "@/components/atomic/BulletList";
import ShareSection from "@/views/share/ShareSection";

export default {
  name: "FAQ",
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "FAQ du site fit-arena.fr. Réservations des salles multisports connectées, intelligentes et autonomes. Partage d'expériences sportives et réservation en ligne",
      },
    ],
    link: [{ rel: "canonical", href: "https://www.fit-arena.fr/faq" }],
  },
  components: {
    NumericList,
    BulletList,
    ShareSection,
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.red {
  color: $doi--color-color0;
}

.font {
  font-family: $doi--text-font-family5;
}

.line {
  width: 80%;
}

.sub-title {
  text-transform: none;
}

@media (min-width: $doi-breakpoint-desktop) {
  .m-l {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

@media (max-width: $doi-breakpoint-mobile) {
  .m-l {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .title {
    font-size: $doi--text-font-size5;
  }

  .sub-title {
    font-size: $doi--text-font-size4;
  }
}
</style>
